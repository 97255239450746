import axios from "axios";
import { push } from "connected-react-router";
import "css/NavigationBar.css";
import logo from "media/logo.jpg";
import { DivisionType, ProgressStatusType } from "models/variables";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import { handleAxiosError, logout } from "redux/Auth/action";
import { setMessageModal, setUserPreferredRow } from "redux/Loading/action";
import { IRootState } from "store";
import { CloseIcon, LogoutIcon, NotificationOffIcon, NotificationOnIcon, SettingIcon, ShowPassword } from "./IconsOnly";

const INTERVAL = "3em";

interface IncompleteOrder {
  id: number;
  orderNumber: string;
  completeDate: string;
  progressStatus: ProgressStatusType;
  division: DivisionType;
}

const NavigationBar = () => {
  const [incompleteOrders, setIncompleteOrders] = useState<IncompleteOrder[]>([]);
  const [showIncompleteOrders, setShowIncompleteOrders] = useState(false);
  const [rowDropDown, setRowDropDown] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const setOldPassword = (text: string) => {
    setInputs({ ...inputs, oldPassword: text });
  };
  const setNewPassword = (text: string) => {
    setInputs({ ...inputs, newPassword: text });
  };
  const setConfirmNewPassword = (text: string) => {
    setInputs({ ...inputs, confirmNewPassword: text });
  };
  const clearPasswordInput = () => {
    setInputs({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  };

  const dispatch = useDispatch();
  const role = useSelector((state: IRootState) => state.auth.role);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;
  const AVAILABLE_ROWS = ["5", "10", "15", "50", `根據螢幕高度 (${maxRow})`];

  const handleSetRow = (row: string) => {
    if (row === `根據螢幕高度 (${maxRow})`) {
      dispatch(setUserPreferredRow(null));
    } else {
      dispatch(setUserPreferredRow(Number(row)));
    }
    setRowDropDown(false);
  };
  const currentPath = window.location.pathname;
  const includeURL = [
    "/all-orders",
    "/service",
    "/maintenance",
    "/order-history",
    "/admin/document",
    "/admin/template",
    "/admin/company",
    "/admin/worker",
    "/admin/client",
  ];

  const disabled = Object.values(inputs).some((i) => !i);

  const handleSubmit = async () => {
    if (inputs.newPassword !== inputs.confirmNewPassword) {
      dispatch(setMessageModal({ isOpen: true, content: "您所輸入的密碼不相同" }));
    } else {
      try {
        await axios.put(`/account/password`, {
          oldPassword: inputs.oldPassword,
          newPassword: inputs.confirmNewPassword,
        });
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        setInputs({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
        setSettingModal(false);
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
        setInputs({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
      }
    }
  };

  const fetchAllOrders = async () => {
    try {
      const d = new Date();
      d.setDate(d.getDate() - 1);
      d.setHours(0, 0, 0, 0);
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/notification/incomplete`);
      url.searchParams.set("progressStatus", "COMPLETED");
      url.searchParams.set("completeDate", new Date(d).toISOString());
      const res = await axios.get(url.toString());
      const result = res.data;
      setIncompleteOrders(result);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const handleRedirect = (division: DivisionType, id: number) => {
    if (division === "MAINTENANCE") {
      dispatch(push(`/maintenance/view/${id}`));
    } else {
      dispatch(push(`/service/view/${id}`));
    }
    setShowIncompleteOrders(false);
  };

  return (
    <div>
      <Navbar className="px-4 flex-row-between" style={{ borderBottom: "solid 0.5px #5554" }} light expand="md">
        <NavbarBrand className="red" href="/">
          <img src={logo} alt="profile" style={{ maxWidth: "56px" }} />
        </NavbarBrand>
        <div className="flex-center">
          {includeURL.includes(currentPath) && (
            <div className="flex-center" style={{ marginRight: INTERVAL }}>
              <div className="mx-2">顯示行數</div>
              <Dropdown
                isOpen={rowDropDown}
                toggle={() => {
                  setRowDropDown(!rowDropDown);
                }}
              >
                <DropdownToggle style={{ color: "#EEE" }}>
                  {userPreferredRow ?? "根據螢幕高度"}
                  <MdKeyboardArrowDown
                    fill={"#EEE"}
                    size={24}
                    style={{
                      marginLeft: "16px",
                      transform: rowDropDown ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                    }}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  {AVAILABLE_ROWS.map((item) => {
                    return (
                      <DropdownItem
                        key={item}
                        md={2}
                        className="d-flex flex-center districtSelectionItem"
                        onClick={() => {
                          handleSetRow(item);
                        }}
                      >
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
          {userRole !== "WORKER" && (
            <div
              className="flex-center relative"
              style={{ marginRight: INTERVAL }}
              onClick={() => setShowIncompleteOrders(!showIncompleteOrders)}
            >
              <div className={`pointer ${incompleteOrders.length && "notificationIconContainer"}`}>
                {incompleteOrders.length ? <NotificationOnIcon /> : <NotificationOffIcon />}
              </div>
              <section
                className="absolute notification"
                style={{
                  opacity: showIncompleteOrders ? 1 : 0,
                }}
              >
                {showIncompleteOrders && (
                  <div className={showIncompleteOrders ? "notificationContainer showUp" : "notificationContainer"}>
                    {incompleteOrders.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="my-2 flex-center notificationContent full-width"
                          onClick={(e) => handleRedirect(item.division, item.id)}
                        >
                          <div className="flex-center" style={{ width: "max-content" }}>
                            <div className="flex-center mx-1 divisionContainer">
                              {item.division === "PROJECT" ? "工" : "維"}
                            </div>
                            <div className="mx-1 textContent">{item.orderNumber}</div>
                            <div className="mx-1 textContent">{"已超過完工日期，但狀態未被設定至「已完成」"}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </section>
            </div>
          )}
          <div className="flex-center pointer" style={{ marginRight: INTERVAL }} onClick={() => setSettingModal(true)}>
            <div style={{ transform: settingModal ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}>
              <SettingIcon />
            </div>
            <div className="mx-2">{`使用者身份: ${role}`}</div>
          </div>
          <div className="flex-center pointer" style={{ marginLeft: "32px" }} onClick={() => dispatch(logout())}>
            <LogoutIcon />
            <h5 className="m-2 flex-center" style={{ fontWeight: 400 }}>
              {"登出"}
            </h5>
          </div>
        </div>
        <Modal isOpen={settingModal}>
          <Container className="p-4">
            <section className="full-width flex-row-between">
              <div className="flex1" />
              <h5 className="flex1 flex-center m-0">密碼設定</h5>
              <div
                className="flex1 flex-row-end pointer"
                onClick={() => {
                  setSettingModal(false);
                  clearPasswordInput();
                }}
              >
                <CloseIcon />
              </div>
            </section>
            <section className="my-2 mt-4 flex-column-center full-width">
              <form className="full-width flex-column-start">
                <InputRow keyValue={"舊密碼"} inputText={inputs.oldPassword} setInputText={setOldPassword} />
                <InputRow keyValue={"新密碼"} inputText={inputs.newPassword} setInputText={setNewPassword} />
                <InputRow
                  keyValue={"確認新密碼"}
                  inputText={inputs.confirmNewPassword}
                  setInputText={setConfirmNewPassword}
                />
              </form>
              <div className="my-4 full-width flex-center">
                <Button className="w-60" color="primary" disabled={disabled} onClick={handleSubmit}>
                  確定
                </Button>
              </div>
            </section>
          </Container>
        </Modal>
      </Navbar>
      {showIncompleteOrders && (
        <div
          className="full-size absolute"
          style={{ top: 0, left: 0, zIndex: 11 }}
          onClick={() => setShowIncompleteOrders(false)}
        />
      )}
    </div>
  );
};

export default NavigationBar;

interface InputRowProps {
  keyValue: string;
  inputText: string;
  setInputText: (args0: string) => void;
}

export const InputRow = (props: InputRowProps) => {
  const { keyValue, inputText, setInputText } = props;
  const [showValue, setShowValue] = useState(false);
  return (
    <div className="my-2">
      <div>{keyValue}</div>
      <div className="flex-center relative">
        <input
          className="inputStyle"
          type={showValue ? "text" : "password"}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
        />
        <div
          className="absolute p-1"
          style={{ right: "-40px" }}
          onTouchStart={() => setShowValue(true)}
          onTouchEnd={() => setShowValue(false)}
          onMouseDown={() => setShowValue(true)}
          onMouseUp={() => setShowValue(false)}
        >
          <ShowPassword />
        </div>
      </div>
    </div>
  );
};
