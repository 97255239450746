import axios from "axios";
import FormHeader from "components/parts/FormHeader";
import SimplePieChart from "components/SimplePieChart";
import StackedColumns from "components/StackedColumns";
import { IJobsStatus, IJobsTotal } from "models/responseModels";
import { DIVISIONS, DivisionType, PROGRESS_STATUS } from "models/variables";
import Layout from "pages/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";

function DashboardPage() {
  const [globalLoading, setGlobalLoading] = useState({
    jobsTotalLoading: false,
    maintenanceJobsStatusLoading: false,
    projectJobsStatusLoading: false,
  });
  const [jobsTotal, setJobsTotal] = useState<IJobsTotal>({
    xAxis: [],
    series: [],
  });
  const [maintenanceJobsStatus, setMaintenanceJobsStatus] = useState<IJobsStatus>({
    labels: [],
    series: [],
  });
  const [projectJobsStatus, setProjectJobsStatus] = useState<IJobsStatus>({
    labels: [],
    series: [],
  });
  const dispatch = useDispatch();

  const DAYS = 30;

  const fetchJobsTotal = async () => {
    setGlobalLoading({ ...globalLoading, jobsTotalLoading: true });

    try {
      const res = await axios.get<IJobsTotal>("/dashboard/order/jobs-total");
      setJobsTotal(res.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      setGlobalLoading({ ...globalLoading, jobsTotalLoading: false });
    }
  };

  const fetchJobsStatus = async (division: DivisionType, days: number) => {
    setGlobalLoading({ ...globalLoading, maintenanceJobsStatusLoading: true, projectJobsStatusLoading: true });
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/dashboard/order/jobs-status`);
      url.searchParams.set("division", division);
      url.searchParams.set("days", String(days));
      const res = await axios.get<IJobsStatus>(url.toString());
      division === "MAINTENANCE" ? setMaintenanceJobsStatus(res.data) : setProjectJobsStatus(res.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      setGlobalLoading({ ...globalLoading, maintenanceJobsStatusLoading: false, projectJobsStatusLoading: false });
    }
  };

  useEffect(() => {
    fetchJobsTotal();
    fetchJobsStatus("MAINTENANCE", DAYS);
    fetchJobsStatus("PROJECT", DAYS);
  }, []);

  return (
    <>
      <Layout>
        <div className="px-4 py-2 pb-4" style={{ overflowY: "auto" }}>
          <FormHeader offset={false} header={"各部門工作單相關數據"} />

          {/* div for pie charts */}
          <section className="mx-3 p-4 flex-center" style={{ alignItems: "flex-start" }}>
            <div className="p-3 mx-1 flex-column-center" style={{ width: "50%", height: "50%" }}>
              <h4 className="mb-4 full-width flex-row-start">維修保養部</h4>
              <h5 className="mb-4 full-width flex-row-start">
                {DAYS}天內建立工作單總數量：
                {maintenanceJobsStatus.series.reduce((partial_sum, a) => partial_sum + a, 0)}
              </h5>
              <div className="mb-4 full-width flex-center">
                {globalLoading.maintenanceJobsStatusLoading ? (
                  <>Loading</>
                ) : maintenanceJobsStatus.series.length && maintenanceJobsStatus.labels.length ? (
                  <SimplePieChart
                    // title={"工作單狀態"}
                    labels={maintenanceJobsStatus.labels.map((label) => PROGRESS_STATUS[label])}
                    series={maintenanceJobsStatus.series}
                    width={"400px"}
                    height={"400px"}
                  />
                ) : (
                  <>No Data</>
                )}
              </div>
            </div>

            <div className="p-3 mx-1 flex-column-center" style={{ width: "50%", height: "50%" }}>
              <h4 className="mb-4 full-width flex-row-start">工程部</h4>
              <h5 className="mb-4 full-width flex-row-start">
                {DAYS}天內建立工作單總數量：
                {projectJobsStatus.series.reduce((partial_sum, a) => partial_sum + a, 0)}
              </h5>
              <div className="mb-4 full-width flex-center">
                {globalLoading.projectJobsStatusLoading ? (
                  <>Loading</>
                ) : projectJobsStatus.series.length && projectJobsStatus.labels.length ? (
                  <SimplePieChart
                    labels={projectJobsStatus.labels.map((label) => PROGRESS_STATUS[label])}
                    series={projectJobsStatus.series}
                    width={"400px"}
                    height={"400px"}
                  />
                ) : (
                  <>No Data</>
                )}
              </div>
            </div>
          </section>
          {/* div for bar chart */}
          <section className="m-4 flex-center">
            <div className="p-3 full-width" style={{}}>
              <h4 className="mb-4 full-width flex-center">各部門過去半年工作單總數</h4>
              <div className="flex-center">
                {globalLoading.jobsTotalLoading ? (
                  <>Loading</>
                ) : jobsTotal.series.length && jobsTotal.xAxis.length ? (
                  <StackedColumns
                    series={jobsTotal.series.map((d) => ({ name: DIVISIONS[d.name], data: d.data }))}
                    height={"400px"}
                    width={"800px"}
                    xAxis={{
                      type: "category",
                      categories: jobsTotal.xAxis.map((date) => {
                        const d = new Date(date);
                        const year = d.getFullYear();
                        const month = d.getMonth() + 1;
                        return `${month}/${year}`;
                      }),
                    }}
                  />
                ) : (
                  <>No Data</>
                )}
              </div>
            </div>
          </section>
          <div style={{ height: "100px" }} />
        </div>
      </Layout>
    </>
  );
}

export default DashboardPage;
