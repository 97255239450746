export const AREAS = Object.freeze({
  HONG_KONG: '香港區',
  KOWLOON: '九龍區',
  NEW_TERRITORIES: '新界區',
  OTHERS: '未分類區域',
  A區域: 'A區域',
  B區域: 'B區域',
  C區域: 'C區域',
});

export const AREAS_MAPPING = Object.entries(AREAS).reduce((acc, cur) => acc.set(cur[1], cur[0]), new Map());

export type AreaType = keyof typeof AREAS;

export const ROLES = Object.freeze({
  SUPER_ADMIN: 'SUPER ADMIN',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  FOREMEN: 'FOREMEN',
  WORKER: 'WORKER',
});

export const ROLES_MAPPING = Object.entries(ROLES).reduce((acc, cur) => acc.set(cur[1], cur[0]), new Map());

export type RoleType = keyof typeof ROLES;

export const DIVISIONS = Object.freeze({
  MAINTENANCE: '維修保養部', // 1
  PROJECT: '工程部', // 2
});
export const DIVISIONS_MAPPING = Object.entries(DIVISIONS).reduce((acc, cur) => acc.set(cur[1], cur[0]), new Map());

export type DivisionType = keyof typeof DIVISIONS;

export const PRIORITIES = Object.freeze({
  NOT_URGENT: '保養維修', //1
  URGENT: '普通維修', //2
  VERY_URGENT: '緊急維修', //3
});

export const PRIORITIES_MAPPING = Object.entries(PRIORITIES).reduce((acc, cur) => acc.set(cur[1], cur[0]), new Map());

export type PrioritiesType = keyof typeof PRIORITIES;

export const SERVICE_TYPES = Object.freeze({
  M1: 'M1 例行保養',
  'M1(E-CALL)': 'M1 免費 e-call',
  'M2(E-CALL)': 'M2 收費 e-call',
  M2: 'M2 維修工程',
  M3: 'M3 維修工程 ',
  P1: 'P1 工程保養',
  P2: 'P2 大型工程',
  'P3(工程收費E-CALL)': 'P3 工程收費 e-call',
});

export const SERVICE_TYPES_MAPPING = Object.entries(SERVICE_TYPES).reduce(
  (acc, cur) => acc.set(cur[1], cur[0]),
  new Map()
);

export type ServiceTypes = keyof typeof SERVICE_TYPES;

export const PROGRESS_STATUS = Object.freeze({
  NEW: '新增', //1
  ASSIGNED: '已安排', //2
  PROCESSING: '現場工作中', //3 工作中 => 現場工作中
  FOLLOWING_UP: '前線跟進中', //4 跟進中 => 前線跟進中
  FRONTLINE_COMPLETED: '前線完成', //6
  待報價: '待報價',
  已報價: '已報價',
  報價已確認: '報價已確認',
  需上單: '需上單',
  已上單: '已上單',
  COMPLETED: '已完成', //5
  客方取消: '客方取消',
  CANCELLED: '錯單取消', //7 已取消 => 錯單取消
});

export const PROGRESS_STATUS_MAPPING = Object.entries(PROGRESS_STATUS).reduce(
  (acc, cur) => acc.set(cur[1], cur[0]),
  new Map()
);

export type ProgressStatusType = keyof typeof PROGRESS_STATUS;

export const progressColor = (status: ProgressStatusType, hover?: boolean) => {
  //紅 =新增
  // 黃 =已安排/現場工作中/前線跟進中/前線完成
  // 綠 =待報價/已報價/需上單/已上單
  // 藍 =已完成 #6BB8FF
  // 深灰 = 客方取消 / 錯單取消

  if (!hover) {
    if (status === 'NEW') {
      return '#FEFFB6BB';
      // return '#FF5C5C55';
    } else if (status === 'COMPLETED') {
      return '#FFF';
    } else if (status === '待報價' || status === '已報價' || status === '需上單' || status === '已上單') {
      return '#6BB8FFAA';
    } else if (status === 'CANCELLED' || status === '客方取消') {
      return '#9998';
    } else {
      return '#C5FFD5BB';
    }
  } else {
    if (status === 'NEW') {
      return '#FEFFB6';
      // return '#FF5C5CAA';
    } else if (status === 'COMPLETED') {
      return '#E8E8E8';
    } else if (status === '待報價' || status === '已報價' || status === '需上單' || status === '已上單') {
      return '#6BB8FF';
    } else if (status === 'CANCELLED' || status === '客方取消') {
      return '#999A';
    } else {
      return '#C5FFD5';
    }
  }
  // if (!hover) {
  //   if (status === 'NEW') {
  //     return '#FEFFB655';
  //   } else if (status === 'COMPLETED') {
  //     return '#FFFFFF00';
  //   } else if (status === 'CANCELLED') {
  //     return '#999A';
  //   } else {
  //     return '#C5FFD555';
  //   }
  // } else {
  //   if (status === 'NEW') {
  //     return '#FEFFB6EE';
  //   } else if (status === 'COMPLETED') {
  //     return '#DDDDDD';
  //   } else if (status === 'CANCELLED') {
  //     return '#999E';
  //   } else {
  //     return '#C5FFD5EE';
  //   }
  // }
};
